import Link from 'next/link'

type LinkTextProps = {
	text: string
	addInnerSpan?: boolean
	addArrowRight?: boolean
}

const LinkText = (props: LinkTextProps) => {

	const { text, addInnerSpan, addArrowRight } = props

	if ( addArrowRight ) {
		return <><span>{text}</span><span className="arrow">→</span></>
	}

	if ( addInnerSpan ) {
		return <span>{text}</span>
	}

	return <>{text}</>

}


type LinkByTypeProps = {
	link: any
	linkClass: string
	addInnerSpan?: boolean
	addArrowRight?: boolean
}

const LinkByType = (props: LinkByTypeProps) => {

	const { link, linkClass, addInnerSpan, addArrowRight } = props

	if ( !link || !link.url ) {
		return null
	}

	switch( link.type ) {
		case 'entry':

			if ( !link.element ) {
				return null
			}

			return (
				<Link href={`/${link.element.uri}`} className={linkClass}>
					<LinkText text={link.text} addInnerSpan={addInnerSpan} addArrowRight={addArrowRight} />
				</Link>
			)

		case 'asset':

			if ( !link.element ) {
				return null
			}

			return (
				<Link href={link.url} className={linkClass} target="_blank" rel="noopener noreferrer">
					<LinkText text={link.text} addInnerSpan={addInnerSpan} addArrowRight={addArrowRight} />
				</Link>
			)

		case 'url':
			return (
				<Link href={link.url} className={linkClass} target="_blank" rel="noopener noreferrer">
					<LinkText text={link.text} addInnerSpan={addInnerSpan} addArrowRight={addArrowRight} />
				</Link>
			)

		case 'email':
			return (
				<Link href={link.url} className={linkClass}>
					<LinkText text={link.text} addInnerSpan={addInnerSpan} addArrowRight={addArrowRight} />
				</Link>
			)

		case 'tel':
			return (
				<Link href={link.url.replace(' ', '')} className={linkClass}>
					<LinkText text={link.text} addInnerSpan={addInnerSpan} addArrowRight={addArrowRight} />
				</Link>
			)

		default:
			return <span>{link.text}</span>
	}
}


LinkByType.defaultProps = {
	link: null,
	linkClass: '',
	addInnerSpan: false,
}

export default LinkByType
