
const ratios: any = {
	"square": [1,1],
	"landscape": [4,3],
	"portrait": [3,4],
	"widescreen": [16,9],
	"widescreen-portrait": [9,16],
}


export function getSrcSet( image: any ) {

	const srcsetArr = image.sizes.map((img: any) => {
		return `${img.url} ${img.width}w`
	})
	return srcsetArr.join(',')

}


const getHeight = function( width: number, ratio: Array<number> ) {
	return Math.floor( ( width/ratio[0] ) * ratio[1] )
}


export const getContainerPadding = function( imageRatio: string ) {

	const selectedRatio = ratios[imageRatio]

	if ( !selectedRatio ) {
		return null
	}

	return ( selectedRatio[1] / selectedRatio[0] ) * 100
}



// Adjust values to match sass settings

const maxWidth: any = 1980
const breakPoints: any = {
	small: 0,
	medium: 640,
	large: 1024,
	xlarge: 1440,
	xxlarge: 1680
}
const outerPadding: any = {
	small: 0,
	medium: 25,
	large: 30,
	xlarge: 40,
	xxlarge: 40
}
const gutters: any = {
	small: 20,
	medium: 20,
	large: 20,
	xlarge: 20,
	xxlarge: 20
}
const columnCount: any = 12


// @param columnsArray - array of column count on all screen sizes from small to xlarge
export function buildSizesAttribute( columnsArray: Array<any> ) {


	let previousColumnCount = 0;
	const returnArr = columnsArray.map(( columns, index ) => {

		// Prevent unnecessary duplicates
		if ( columns === previousColumnCount || columns > columnCount ) {
			return ''
		}
		previousColumnCount = columns

		// Set fallback size
		if ( index === 0 ) {
			return `${(columns/columnCount)*100}vw`
		}

		// Set breakpoint sizes
		let key = Object.keys(breakPoints)[index]
		return `(min-width: ${breakPoints[key]}px) ${Math.floor((columns/columnCount)*100)}vw`

	})

	// Add full size
	const lastColCount = columnsArray.slice(-1)[0]
	// const lastColSize = Math.floor(maxWidth*(lastColCount/columnCount))
	const lastColSize = Math.floor((maxWidth-(outerPadding['xxlarge']*2))*(lastColCount/columnCount))

	returnArr.push(`(min-width: ${maxWidth}px) ${Math.floor(lastColSize-gutters['xxlarge'])}px`)

	return returnArr.filter(str => str.length > 0).reverse().join(', ')

}

