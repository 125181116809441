import Link from 'next/link'
import LinkByType from './LinkByType'

type LinksListProps = {
	links: Array<any>
	containerClass?: string
	itemClass?: string
	linkClass?: string
}

const LinksList = (props: LinksListProps) => {

	const { links, containerClass, itemClass, linkClass } = props

	if (!links || links.length === 0) {
		return null
	}

	return (
		<ul className={containerClass}>
			{links.map((link: any) => {

				if ( !link.linkTo ) {
					return null
				}

				return (
					<li key={link.id} className={itemClass}>
						<LinkByType link={link.linkTo} linkClass={linkClass} />
					</li>
				)
			})}
		</ul>

	)

}

LinksList.defaultProps = {
	links: [],
	containerClass: 'button-row',
	itemClass: 'button-row__item',
	linkClass: 'button button--inverted',
}

export default LinksList
