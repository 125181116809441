import { useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { getSrcSet } from '../../lib/helpers/images'

type ResponsiveImageProps = {
	image: any
	imageClass?: string
	containerClass?: string
	sizesAttribute?: string
	cleanImplementation?: boolean
}


const Image = ( props: any ) => {

	const { image, imageClass, sizesAttribute, isRequested, placeholder, style } = props

	return (
		<img
			className={imageClass ? imageClass : ''}
			style={style}
			src={placeholder.url}
			width={placeholder.width}
			height={placeholder.height}
			srcSet={isRequested ? getSrcSet(image) : `${placeholder.url} 50w`}
			sizes={sizesAttribute ? sizesAttribute : '100vw'}
			alt={image.title}
		/>
	)

}


const ResponsiveImage = (props: ResponsiveImageProps) => {

	const { image, imageClass, containerClass, sizesAttribute } = props

	const [ isRequested, setIsRequested ] = useState(false)
	const { ref, inView, entry } = useInView({
		threshold: 0,
	});

	useEffect(() => {
		if ( inView && !isRequested ) {
			setIsRequested(true)
		}
	}, [ inView, isRequested ])

	if ( !image ) {
		return null
	}

	const placeholder = image.sizes && image.sizes.length > 0 ? image.sizes[0] : null
	if ( !placeholder ) {
		return null
	}


	if ( props.cleanImplementation ) {
		return (
			<div className={containerClass ? containerClass : 'image-container'} ref={ref}>
				<Image
					image={image}
					imageClass={imageClass}
					placeholder={placeholder}
					sizesAttribute={sizesAttribute}
					isRequested={isRequested}
					style={{
						objectPosition: image.focalPoint ? `${image.focalPoint[0]*100}% ${image.focalPoint[1]*100}%` : null
					}}
				/>
			</div>
		)
	}


	return (

		<div
			ref={ref}
			className={containerClass ? containerClass : 'image-container'}
			style={{
				width: '100%',
				position: 'relative',
				paddingTop: `${((placeholder.height/placeholder.width)*100).toFixed(2)}%`
			}}
		>
			<Image
				image={image}
				placeholder={placeholder}
				imageClass={imageClass}
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					zIndex: 0
				}}
				sizesAttribute={sizesAttribute}
				isRequested={isRequested}
			/>
		</div>
	)

}

export default ResponsiveImage
