
import PageHeaderBasicLockUp from './PageHeaderBasicLockUp'
import PageHeaderBasic from './PageHeaderBasic'
import FullScreenHero from '../../hero/FullScreenHero'

// TODO - cleanup swap
import LockUpSimple from '../../hero/LockUpSimple'

interface PageHeaderProps {
	page: any
}

// Handles all types of page headers

const PageHeader = (props: PageHeaderProps) => {

	const { page } = props
	const pageHeader = page.pageHeader?.length > 0 ? page.pageHeader[0] : null

	if ( !pageHeader ) {
		return null
	}

	if ( pageHeader.typeHandle === 'basicCentered' ) {
		return <PageHeaderBasic block={pageHeader} />
	}

	if ( pageHeader.typeHandle === 'basicLockUp' ) {
		return <PageHeaderBasicLockUp block={pageHeader} />
	}

	if ( pageHeader.typeHandle === 'heroFullscreen' ) {
		return <FullScreenHero block={pageHeader} />
	}

	// TODO - add hero lock up

	return null

}

export default PageHeader
