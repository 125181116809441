import LinkByType from './LinkByType'

type LinkButtonProps = {
	link: any
	linkClass: string
	containerClass: string
}

const LinkButton = (props: LinkButtonProps) => {

	const { link, linkClass, containerClass } = props

	if ( !link || !link.url ) {
		return null
	}

	return (
		<div className={containerClass}>
			<LinkByType link={link} linkClass={linkClass} addInnerSpan={true} addArrowRight={true}/>
		</div>
	)

}

LinkButton.defaultProps = {
	link: null,
	linkClass: 'button button--standard',
	containerClass: 'button-container',
}

export default LinkButton
