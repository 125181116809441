import Link from 'next/link'
import LinkByType from '../../common/LinkByType'
import {
	GrayBackgroundSmaller,
	BlueBackgroundSmaller
} from '../../svgs/backgrounds'

interface BasicLockUpProps {
	block: any
}

// TODO - check usage and rename accordingly
// This component is only used as page header

const BasicLockUp = (props: BasicLockUpProps) => {

	const { block } = props
	const { heading, text, links, pageHeaderColorTheme } = block
	const blueBackground = pageHeaderColorTheme === 'blueBackground'

	return (
		<div className={`lock-up-simple lock-up-simple--background-${blueBackground ? 'blue' : 'gray'}`}>
			<div className={`lock-up-simple__wrapper`}>
				<div className={`lock-up-simple__content`}>
					{heading && <h1>{heading}</h1>}
					{text && <span dangerouslySetInnerHTML={{ __html: text }} />}
					{links && links.length > 0 && (
						<ul className='lock-up-simple__cta-list'>
							{links.map((link: any) => {
								if (link.linkTo) {
									return (
										<li key={link.id} className='lock-up-simple__cta-list-item'>
											<LinkByType link={link.linkTo} linkClass={`button button--${blueBackground ? 'on-dark' : 'inverted'}`} />
										</li>
									)
								}
							})}
						</ul>
					)}
				</div>
			</div>
			<div className="lock-up-simple__background">
				{blueBackground ? (
					<BlueBackgroundSmaller />
				) : (
					<GrayBackgroundSmaller />
				)}

			</div>
		</div>
	)

}

export default BasicLockUp
