export const GrayBackground = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440.45 600" preserveAspectRatio="xMinYMin slice">
			<defs>
				<style>
					{`.gray-background-shape-one {
						opacity: 0.1;
						fill: #9e8c70;
					}
					.gray-background-shape-two {
						opacity: 0.15;
						fill: #9e8c70;
					}
					.gray-background-background {
						fill: #EBE9E4;
					}`}
				</style>
			</defs>
			<rect className="gray-background-background" x=".45" y="0" width="1440" height="600" />
			<path className="gray-background-shape-one" d="M.45,226.6c159.8-23.33,763.32-76.76,1121.25,373.4L.45,599.5" />
			<path className="gray-background-shape-two" d="M0,0H62.93C216.41,152.61,357.99,353.44,405.8,600l-405.35-.5" />
		</svg>
	)
}

export const GrayBackgroundSmaller = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1078.63 600" preserveAspectRatio="xMinYMin slice">
			<defs>
				<style>
					{`.gray-background-shape-one {
						opacity: 0.1;
						fill: #9e8c70;
					}
					.gray-background-shape-two {
						opacity: 0.15;
						fill: #9e8c70;
					}
					.gray-background-background {
						fill: #EBE9E4;
					}`}
				</style>
			</defs>
			<rect className="gray-background-background" x="0" width="1078.63" height="600" />
			<path className="gray-background-shape-one" d="M0,361c141.69-20.68,632.25-65.03,991.13,239H0s0-239,0-239" />
			<path className="gray-background-shape-two" d="M0,8.68C150.12,160.2,287.29,358.09,334.2,600L0,599.5V8.68Z" />
		</svg>
	)
}

export const BlueBackground = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440.45 600" preserveAspectRatio="xMinYMin slice">
			<defs>
				<style>
					{`.gray-background-shape-one {
						opacity: 0.1;
						fill: #9e8c70;
					}
					.gray-background-shape-two {
						opacity: 0.15;
						fill: #9e8c70;
					}
					.gray-background-background {
						fill: #081549;
					}`}
				</style>
			</defs>
			<rect className="gray-background-background" x=".45" y="0" width="1440" height="600" />
			<path className="gray-background-shape-one" d="M.45,226.6c159.8-23.33,763.32-76.76,1121.25,373.4L.45,599.5" />
			<path className="gray-background-shape-two" d="M0,0H62.93C216.41,152.61,357.99,353.44,405.8,600l-405.35-.5" />
		</svg>
	)
}

export const BlueBackgroundSmaller = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1078.63 600" preserveAspectRatio="xMinYMin slice">
			<defs>
				<style>
					{`.gray-background-shape-one {
						opacity: 0.1;
						fill: #9e8c70;
					}
					.gray-background-shape-two {
						opacity: 0.15;
						fill: #9e8c70;
					}
					.gray-background-background {
						fill: #081549;
					}`}
				</style>
			</defs>
			<rect className="gray-background-background" x="0" width="1078.63" height="600" />
			<path className="gray-background-shape-one" d="M0,361c141.69-20.68,632.25-65.03,991.13,239H0s0-239,0-239" />
			<path className="gray-background-shape-two" d="M0,8.68C150.12,160.2,287.29,358.09,334.2,600L0,599.5V8.68Z" />
		</svg>
	)
}



