import LinkButton from '../../common/LinkButton'
import {
	GrayBackground,
	GrayBackgroundSmaller,
	BlueBackgroundSmaller
} from '../../svgs/backgrounds'

interface PageHeaderBasicProps {
	block: any
	fallbackHeading?: string
	forceNoBackground?: boolean
	hasBlueBackground?: boolean
}

const PageHeaderBasic = (props: PageHeaderBasicProps) => {

	const {
		block,
		fallbackHeading,
		forceNoBackground,
		hasBlueBackground
	} = props

	if ( !block ) {
		return null
	}

	const hasBackground = !forceNoBackground && block.pageHeaderBasicStyle === 'grayBackground'

	return (
		<header className={`page-header-basic page-header-basic--${hasBackground ? 'has-background' : 'clean'}`}>
			<div className="page-header-basic__wrapper">
				<div className="page-header-basic__heading">
					{block.kicker && <span>{block.kicker}</span>}
					<h1>{block.heading ? block.heading : fallbackHeading}</h1>
				</div>
				{block.text && <div className="page-header-basic__text" dangerouslySetInnerHTML={{__html: block.text}} />}
				<LinkButton
					link={block.linkTo}
					linkClass={`button button--${hasBackground ? 'inverted' : 'standard'}`}
					containerClass="page-header-basic__button-container"
				/>
			</div>
			{hasBackground &&
				<div className="page-header-basic__background">
					{hasBlueBackground ? <BlueBackgroundSmaller /> : <GrayBackgroundSmaller />}
				</div>
			}
		</header>
	)
}

export default PageHeaderBasic
