import { useState, useEffect, useRef } from 'react'

import Link from 'next/link'
import LinksList from '../common/LinksList'
import LinkByType from '../common/LinkByType'
import ResponsiveImage from '../common/ResponsiveImage'

interface FullScreenHeroProps {
	block: any
}

const HeroVideo = ({ video, poster }: any) => {
	const [powerSaveMode, setPowerSaveMode] = useState<boolean>(false);

	const videoRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		const videoNode = videoRef.current;

    	if (videoNode) {
    		 videoNode.play()
                .then(() => {
                    // Full power mode
                })
                .catch((error) => {
                    // Power save mode
                    setPowerSaveMode(true);
                });
		}
  	});

	return (
		<div className="full-screen-hero__media-container">
			{!powerSaveMode &&
				<video ref={videoRef} autoPlay muted playsInline loop poster={poster ? poster : ''}>
					<source src={video.url} type={video.mimeType} />
				</video>
			}
			{powerSaveMode &&
				<img src={poster ? poster : ''} className="power-save-mode-fallback-image"/>
			}
		</div>
	)
}

const FullScreenHero = (props: FullScreenHeroProps) => {

	const { block } = props

	if (!block) {
		return null
	}

	const media = block.backgroundMedia && block.backgroundMedia.length > 0 ? block.backgroundMedia[0] : null
	const posterImage = block.image && block.image.length ? block.image[0].sizes.slice(-1) : null;

	return (
		<div className={`full-screen-hero full-screen-hero--theme-${block.textColor ? block.textColor : 'light'}`}>
			{media &&
				<div className="full-screen-hero__media">
					{media.mimeType === 'video/mp4' ? (
						<HeroVideo video={media} poster={posterImage && posterImage[0] ? posterImage[0].url : null} />
					) : (
						<ResponsiveImage
							image={media}
							containerClass={`full-screen-hero__media-container`}
							cleanImplementation={true}
						/>
					)}
				</div>
			}

			<div className="full-screen-hero__overlay">
				<div className="full-screen-hero__content">
					<div className="full-screen-hero__content-wrapper">
						<div className="full-screen-hero__content-container">
							<h1>{block.heading}</h1>
							{block.text && <div dangerouslySetInnerHTML={{ __html: block.text }} />}
						</div>
					</div>
				</div>
				{block.links && block.links.length > 0 && (
					<div className="full-screen-hero__cta">
						<div className="full-screen-hero__cta-wrapper">
							<div className="full-screen-hero__cta-content">
								<p>Quick links</p>
								<LinksList
									links={block.links}
									containerClass="full-screen-hero__cta-list"
									itemClass="full-screen-hero__cta-list-item"
									linkClass="button button--inverted button--inverted__white-bg"
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)

}
export default FullScreenHero
